import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  Renderer2,
  OnInit,
  Input,
  OnDestroy,
} from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { GlobalService } from '../../services/global.service';
import { QuoteService } from '../../services/quote.service';
import { Modal } from 'bootstrap';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { SubmissionStatus, TravelerValidatorService } from '../../services/traveler-validator.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { LocalCacheService } from '../../services/localCache.service';
import { customEmailValidator } from '../../services/email.validator';
import { GAService } from '../../services/ga.service';

@Component({
  selector: 'app-email-quote',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ToastModule],
  templateUrl: './email-quote.component.html',
  styleUrl: './email-quote.component.scss',
  providers: [MessageService],
})
export class EmailQuoteComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  emailId: string = '';
  modalInstance: any;
  emailForm: FormGroup = new FormGroup({});
  isTravelerFormInvalid: boolean = false;
  isEmailDisclaimer: boolean = false;
  isButtonDisabled: boolean = false;
  constructor(
    public globalService: GlobalService,
    private quoteService: QuoteService,
    private router: Router,
    private renderer: Renderer2,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private travelerValidatorService: TravelerValidatorService,
    private localCacheService: LocalCacheService,
    private gaService: GAService
  ) { }

  @Input() isVisible = false;

  openModal(isTravelerFormInvalid: boolean = false) {
    this.isTravelerFormInvalid = isTravelerFormInvalid;
    this.isVisible = true;
    const travelerDetails = this.localCacheService.getSessionStorage('travelerDetails') || {};
    if (travelerDetails?.contactAndAddressDetails?.emailId) {
      this.emailForm.patchValue({
        emailAddress: travelerDetails?.contactAndAddressDetails?.emailId
      });
    }
  }
  closeModal() {
    this.isVisible = false;
    this.emailForm.reset();
  }
  closeEmailDisclaimerModal() {
    this.isEmailDisclaimer = false;
  }
  get emailAddress() { 
    return this.emailForm.get('emailAddress');
  }

  ngOnInit(): void {
    this.emailForm = this.formBuilder.group({
      emailAddress: new FormControl('', [
        customEmailValidator(),
        Validators.required,
      ]),
    });

    this.subscriptions.add(
      this.travelerValidatorService.triggeredSubmittionStatus$.subscribe((submission: SubmissionStatus) => {
        if (this.router.url == '/quotes-steps-two') {
          if (submission.Status && this.emailForm.value.emailAddress) {
            this.subscriptions.add(
              this.globalService.sendEmail(this.emailForm.value.emailAddress).subscribe({
                next: (response) => {
                  if (response && response.coiResponse.emailSent) {
                    this.gaService.sendAddToWishlistEventDetail();
                    this.showSuccessToast();
                    this.closeModal();
                    this.isEmailDisclaimer = true;
                    submission.Status = false;
                    this.emailForm.value.emailAddress = "";
                  }
                },
                error: (error) => {
                  console.log('sendEmail error', error);
                },
              })
            );
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  @ViewChild('emailQuote', { static: false }) emailQuoteModal!: ElementRef;

  sendEmail() {
    this.isButtonDisabled = true;
    setTimeout(() => {
      this.isButtonDisabled = false;
    }, 1500);
    const pathSegment = this.router.url;
    if (this.emailForm.invalid) {
      this.emailForm.markAllAsTouched();
      return;
    }
    if (pathSegment.includes('/quotes-steps-one') || pathSegment === '/quotes-steps-two') {
      this.quoteService.submitQuote('', 'send-email').subscribe((response) => {
        if (!response.error) {
          const email = this.emailForm.value.emailAddress;
          this.globalService.sendEmail(email).subscribe({
            next: (response) => {
              if (response && response.coiResponse.emailSent) {
                this.gaService.sendAddToWishlistEventDetail();
                this.showSuccessToast();
                this.closeModal();
                this.isEmailDisclaimer = true;
              }
            },
            error: (error) => {
              console.log('sendEmail error', error);
            },
          });
        } else {
          console.log('sendEmail submission error', response);
        }
      });
    }
    else {
      this.globalService.sendEmail(this.emailForm.value.emailAddress).subscribe({
        next: (response) => {
          console.log('sendEmail response', response);
          if (response && response.coiResponse.emailSent) {
            this.gaService.sendAddToWishlistEventDetail();
            this.showSuccessToast();
            this.closeModal();
            this.isEmailDisclaimer = true;
          }
          //this.hideModal();
        },
        error: (error) => {
          console.log('sendEmail error', error);
          //this.hideModal();
        },
      });
    }
  }

  showSuccessToast() {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: 'Email Sent Successfully!',
      life: 3000,
    });
  }
}
