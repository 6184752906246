import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxBraintreeService } from '../../services/ngx-braintree.service';
import { ConfigureDropinService } from '../../services/configure-dropin.service';
import { NgxBraintreeDirective } from './ngx-braintree.directive';
import { Router } from '@angular/router';
import { LocalCacheService } from '../../services/localCache.service';
import { environment } from '../../../environments/environment';
import { GlobalService } from '../../services/global.service';
import { GAService } from '../../services/ga.service';
import { DialogModule } from 'primeng/dialog';
import { SegmentService } from '../../services/segment.service';
import { throwError } from 'rxjs';

declare var braintree: any;
declare var paypal: any;

@Component({
  selector: 'app-braintree-widget',
  standalone: true,
  imports: [CommonModule, NgxBraintreeDirective, DialogModule],
  templateUrl: './braintree-widget.component.html',
  styleUrl: './braintree-widget.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class BraintreeWidgetComponent {
  @Input() autoClub: string = environment.autoClub;
  @Input() totalAmount: any;
  @Input() quoteId: any;
  @Input() createPurchase: Function = (
    x_api_key: any,
    completePurchaseObject: any,
  ) => this.service.createPurchase(x_api_key, completePurchaseObject);
  paymentBaseUrl = `${environment.baseAPIUrl}`; //    'https://ca-dev-tmma-ltx-api.delightfulocean-5e7289f6.australiaeast.azurecontainerapps.io/api';
  x_api_key = '';
  completePurchaseObject: any;
  clientTokenNotReceived: boolean = false;
  interval: any;
  instance: any;
  dropinConfig: any = {};
  showCardholderName: any;
  enablePaypalCheckout: any;
  enablePaypalVault: any;
  locale: any;
  errorMessage: any;
  unsuccessfulMessage: any;
  dropinLoaded: any;
  showPayButton: boolean = false;
  enablePayButton: boolean = false;
  payButtonStatus: any;
  changeDetectorRef: any;
  form: any;
  nonce: any;
  enableGooglePay: boolean = false;
  // totalAmount = '2.2';
  googlePayTransactionInfo: any = {};
  applePayTotal: any = {};
  enableApplePay: boolean = false;
  agentGuid: string = '';
  isButtonDisabled: boolean = false;
  clientToken = '';
  paymentStatusCode = 0;
  constructor(
    private service: NgxBraintreeService,
    private configureDropinService: ConfigureDropinService,
    private router: Router,
    private localCacheService: LocalCacheService,
    private globalService: GlobalService,
    private gaService: GAService,
    private segmentService: SegmentService,
  ) {}

  ngOnInit() {
    this.googlePayTransactionInfo = {
      totalPriceStatus: 'FINAL',
      totalPrice: `${this.totalAmount}`,
      currencyCode: 'AUD',
    };
    this.applePayTotal = {
      label: 'My Store',
      amount: this.totalAmount,
    };
    var url = `${this.paymentBaseUrl}quote/token/braintree/RAA`;
    this.agentGuid = this.localCacheService.getSessionStorage('agentGuid');
    this.service.getClientToken(url).subscribe((token: string) => {
      this.clientToken = token;
      this.generateDropInUI(token);
    });

    console.log('ammount', this.totalAmount);
  }
  generateDropInUI(clientToken: string) {
    this.interval = setInterval(() => {
      this.createDropin(clientToken);
    }, 0);
  }

  createDropin(clientToken: string) {
    this.showCardholderName = true;
    this.enablePaypalVault = true;
    this.enablePaypalCheckout = false;
    this.locale = false;
    this.enableGooglePay = true;
    this.enableApplePay = true;
    this.errorMessage = '';
    this.unsuccessfulMessage = '';

    if (this.agentGuid) {
      this.enablePaypalVault = false;
      this.enableGooglePay = false;
      this.enableApplePay = false;
    }

    if (typeof braintree !== 'undefined') {
      this.dropinConfig.authorization = clientToken; //"sandbox_q7g6vg96_2j44s6txx9wphmdz";//clientToken;
      this.dropinConfig.container = '#dropin-container';

      //Not being used
      if (this.showCardholderName) {
        this.configureDropinService.configureCardHolderName(this.dropinConfig);
      }
      //Not being used
      // if (this.enablePaypalCheckout) {
      //     this.configureDropinService.configurePaypalCheckout(this.dropinConfig, "10.00", "AUS");
      // }
      if (this.enablePaypalVault) {
        this.configureDropinService.configurePaypalVault(this.dropinConfig);
      }
      //Not being used
      // if (this.locale) {
      //     this.configureDropinService.configureLocale(this.dropinConfig, this.locale);
      // }
      if (this.enableGooglePay) {
        this.configureDropinService.configureGooglePay(
          this.dropinConfig,
          this.googlePayTransactionInfo,
        );
      }
      if (this.enableApplePay) {
        this.configureDropinService.configureApplePay(
          this.dropinConfig,
          this.applePayTotal,
        );
      }
      braintree.dropin.create(
        this.dropinConfig,
        (createErr: any, instance: any) => {
          if (createErr) {
            console.log(createErr);
            return;
          }
          this.instance = instance;
          this.togglePayButton(true);
          if (this.instance.isPaymentMethodRequestable()) {
            console.log('Enable pay button');
          }
          this.instance.on('paymentMethodRequestable', (event: any) => {
            this.togglePayButton(false);
            console.log('paymentMethodRequestable');
          });
          this.instance.on('noPaymentMethodRequestable', (event: any) => {
            this.togglePayButton(true);
            console.log('noPaymentMethodRequestable');
          });
          // this.instance.requestPaymentMethod(function (err: any, payload: any) {
          //     // Submit payload.nonce to your server
          //     console.log('requestPaymentMethod');
          // });
          this.instance.on('changeActiveView', function () {
            console.log('changeActiveView');
          });
        },
      );
      clearInterval(this.interval);
    }
  }
  togglePayButton(hide: boolean = true) {
    var content: any = document.getElementById('paymentBtn');
    content.style.display = hide ? 'none' : 'block';
  }

  Pay(): void {
    this.isButtonDisabled = true;
    setTimeout(() => {
      this.isButtonDisabled = false;
    }, 1500);
    if (this.instance) {
      this.errorMessage = '';
      this.unsuccessfulMessage = '';
      this.instance.requestPaymentMethod((err: any, payload: any) => {
        if (err) {
          this.errorMessage =
            err?.message == 'No payment method is available.'
              ? 'No payment method is selected. Please choose a way to pay.'
              : err?.message;
          return;
        }
        var randomno = Math.floor(Math.random() * 90000) + 10000;
        this.completePurchaseObject = {
          quoteId: this.quoteId,
          autoClub: this.autoClub,
          amount: this.totalAmount,
          cashReceiptNumber: '',
          nonce: payload.nonce,
          methodType: payload.type,
        };
        this.nonce = payload.nonce;
        const agentGuid = this.localCacheService.getSessionStorage('agentGuid');
        const branchGuid =
          this.localCacheService.getSessionStorage('branchGuid');
        if (agentGuid) {
          this.completePurchaseObject.agentInfo = {
            agentGuid: agentGuid,
            branchGuid: branchGuid || '',
          };
        }
        this.segmentService.segmentPaymentPolicyDetails();
        //Make server call to settle the payment
        this.createPurchase('', this.completePurchaseObject).subscribe({
          next: (response: any) => {
            this.segmentService.segmentCreate_Purchase(response);
            if (response) {
              if (response && response?.status === 'Failed') {
                this.unsuccessfulMessage =
                  'Something went wrong! Please contact customer support for assistance.';
              } else {
                var strResult = JSON.stringify(response);
                this.localCacheService.saveSessionStorage(
                  'paymentInfo',
                  strResult,
                );
                var sendPolicyObj: any = {
                  movementGuid: response.movementGuid,
                  movementType: 'NB',
                };
                this.service
                  .sendPolicy(sendPolicyObj)
                  .subscribe((response) => {});
                this.segmentService.segmentSend_Policy();
                this.globalService.checkAndValidatePage(4, true);
                this.gaService.sendAddPaymentInfoEventDetail(
                  this.completePurchaseObject.methodType,
                );
                this.router.navigate(['payment-confirmation']);
              }
            }
          },
          error: (error: any) => {
            this.segmentService.segmentCreate_Purchase(error);
            console.log('createPurchase error', error);
            this.paymentStatusCode = error?.StatusCode || 0;
            if (
              error &&
              error?.Success === false &&
              error?.StatusCode !== 500
            ) {
              this.unsuccessfulMessage = error?.Message;
            } else {
              this.unsuccessfulMessage =
                'Something went wrong! Please contact customer support for assistance.';
              throwError(error);
            }
          },
        });
      });
    }
  }

  handlePaymentDecline() {
    // Destroy the existing Braintree Drop-in instance if it exists
    if (this.instance) {
      this.instance.teardown((teardownErr: any) => {
        if (teardownErr) {
          console.error('Error during teardown:', teardownErr);
          return;
        }
        console.log('Drop-in instance destroyed');
        this.instance = null; // Clear the existing instance

        // Now reinitialize the dropin
        this.createDropin(this.clientToken);
      });
    } else {
      // If there's no existing instance, create one directly
      this.createDropin(this.clientToken);
    }
  }

  chargeAmount(dropinConfig: any, chargeAmount: any, currency: any) {
    throw new Error('Method not implemented.');
  }
  currency(dropinConfig: any, chargeAmount: any, currency: any) {
    throw new Error('Method not implemented.');
  }
  logError(createErr: any, Error: any) {
    throw new Error('Method not implemented.');
  }

  hidePaymentAlert() {
    this.errorMessage = '';
  }

  hideUnsuccessfulAlert() {
    this.unsuccessfulMessage = '';
    if (this.paymentStatusCode === 2001) {
      // Reload widget for Insufficient Funds declined error.
      this.handlePaymentDecline();
    }
  }
}
